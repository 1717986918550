<template>
  <div class="container">
    <ek-legend />
    <c-1></c-1>
  </div>
</template>
  
  <script>
import C1 from './components/c1.vue'
export default {
  components: { C1 },
}
</script>
  
  <style scoped lang="scss">
.container {
  @include flex(column);
  justify-content: flex-end;
}
.total {
  margin-top: 10px;
}
.ek-legend {
  bottom: 504px;
}
</style>
  