<template>
    <ek-panel title="事件诉求分析">
        <div class="box">
            <div class="border-bottom">
                <div class="title">
                    <span>诉求类型分析</span>
                    <el-date-picker v-model="date"
                                    type="daterange"
                                    popper-class="el-date-style"
                                    :unlink-panels="true"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                    </el-date-picker>
                </div>

                <div class="i-box">
                    <div class="item"
                         v-for="(item, index) in items"
                         :key="index">
                        <section>
                            <span>No.{{ index + 1 }}</span> <span>{{ item.name }}</span>
                            <span>{{ item.total }}</span>
                        </section>
                        <div class="tiaoBgc">
                            <div class="tiao"
                                 :style="{ width: item.total }"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="border-bottom dispose-box">
                <div class="left">
                    <div style="margin-top: 10px">处置状态分析</div>
                    <div class="left-header">
                        <img src="../../../../../public/images/components/bdg/jqzl/czsan.png"
                             alt="" />
                        <div>
                            <span>{{ eventTotal }}件</span>
                            <span>总数</span>
                        </div>
                    </div>
                    <div class="left-body">
                        <section v-for="(item, index) in eventState"
                                 :key="index">

                            <i>{{ item.GovernEventStatusAnalysis.CATEGORY_NUM }}</i>
                            <span>{{ item.GovernEventStatusAnalysis.CATEGORY_NAME }}</span>
                        </section>
                    </div>
                    <div class="left-bottom">
                        <section v-for="(item, index) in eventLightList"
                                 :key="index">
                            <div>
                                <img v-show="index == 2"
                                     src="../../../../../public/images/components/bdg/jqzl/event3.png"
                                     alt="" />
                                <img v-show="index == 1"
                                     src="../../../../../public/images/components/bdg/jqzl/event2.png"
                                     alt="" />
                                <img v-show="index == 0"
                                     src="../../../../../public/images/components/bdg/jqzl/event1.png"
                                     alt="" />
                                <span>{{ item.GovernEventStatusAnalysis.CATEGORY_NAME }}</span>
                            </div>
                            <i :style="{
                  color:
                    index == 2 ? '#0ae0ac' : index == 1 ? '#fda500' : '#ff2f2f',
                }">{{ item.GovernEventStatusAnalysis.CATEGORY_NUM }}</i>
                        </section>
                    </div>
                </div>
                <div class="right">
                    <div style="margin-top: 26px">处置反馈分析</div>
                    <ek-chart :options="options"
                              :animationData="options.series[0].data" />
                </div>
            </div>
            <div class="table">
                <p>高效社区排名</p>
                <div class="table_header lucencys">
                    <span>排名</span>
                    <span>社区</span>
                    <span>响应率</span>
                    <span>办结率</span>
                    <span>满意率</span>
                </div>
                <div class="t-list">
                    <swiper ref="orderSwiper"
                            :options="swiperOptions">
                        <swiper-slide v-for="(items, index) in tableData"
                                      :key="index">
                            <div :class="['item', index == 1 ? 'lucency' : index == 3 ? 'lucency' : '']"
                                 v-for="(item, index) in items"
                                 :key="index">
                                <span>{{ item.num }}</span>
                                <span>{{ item.GovernCommunityRank.COMMUNITY_NAME }}</span>
                                <span>{{ item.GovernCommunityRank.RESPONSE_RATE }}</span>
                                <span>{{ item.GovernCommunityRank.COMPLETION_RATE }}</span>
                                <span>{{ item.GovernCommunityRank.SATISFACTION_RATE }}</span>
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
        </div>
    </ek-panel>
</template>
  
  <script>
import { requestPostBi } from '@/utils/requestMiddleground'
export default {
    data() {
        return {
            items: [], //上方柱状图
            eventState: [], //事件状态
            eventLightList: [], //事件类型
            date: '',
            tableData: [],
            swiperOptions: {
                direction: 'vertical',
                autoplay: {
                    delay: 6000,
                    disableOnInteraction: false,
                },
                speed: 900,
                loop: false,
            },
            options: {
                tooltip: {
                    trigger: 'item',
                    confine: true,
                    backgroundColor: 'transparent',
                    borderColor: 'transparent',
                    padding: 0,
                    formatter: (params) => {
                        let el = ''
                        el = `<div class="tooltip-box row">
                      <div class="text" style="font-size:18px">
                        <span class="t">${params.name}：</span>
                        <span class="v">${params.value}%</span>
                      </div>
                    </div>`
                        return el
                    },
                },
                color: ['#ff2f2f', '#ff9900', '#0ae0ac'],
                legend: [],
                series: [
                    {
                        type: 'pie',
                        radius: ['30%', '50%'],
                        center: ['60%', '40%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center',
                        },
                        itemStyle: {
                            borderWidth: 2,
                            borderColor: '#4A4D4D',
                        },
                        emphasis: {
                            label: {
                                formatter: `{b} \n {d}%`,
                                show: true,
                                fontSize: 18,
                                borderWidth: 0,
                                color: '#fff',
                            },
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [],
                    },
                ],
            },
            eventTotal: '',
        }
    },
    mounted() {
        this.getUp()
        this.getCenter()
        this.getCenterRight()
        this.getDown()
    },
    methods: {
        getUp() {
            let par = {
                "GovernEventCategoryAnalysises[]": {
                    "GovernEventCategoryAnalysis": {
                        "@column": "CATEGORY_NAME;sum(CATEGORY_NUM):TOTAL",
                        "DATE_TAG>=": "2023-07-22",
                        "DATE_TAG<=": "2023-07-24",
                        "@order": "TOTAL-",
                        "@group": "CATEGORY_NAME"
                    },
                    "count": 0,
                    "query": 2
                }
            }
            requestPostBi('', par)
                .then((res) => {
                    let eventTotal = 0
                    res['GovernEventCategoryAnalysises[]'].forEach(f => {
                        eventTotal += f.GovernEventCategoryAnalysis.TOTAL
                    })
                    this.items = res['GovernEventCategoryAnalysises[]'].map((item) => ({
                        name: item.GovernEventCategoryAnalysis.CATEGORY_NAME,
                        total: (item.GovernEventCategoryAnalysis.TOTAL / eventTotal * 100).toFixed(0) + '%',
                    }))
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getCenter() {
            let par = {
                "GovernEventStatusAnalysises[]": {
                    "GovernEventStatusAnalysis": {
                        "@column": "CATEGORY_NAME,CATEGORY_NUM"
                    },
                    "count": 0,
                    "query": 2
                }
            }
            requestPostBi('', par)
                .then((res) => {
                    if (res.code == 200) {
                        res['GovernEventStatusAnalysises[]'].map(m => {
                            if (m.GovernEventStatusAnalysis.CATEGORY_NAME == "TOTAL") {
                                this.eventTotal = m.GovernEventStatusAnalysis.CATEGORY_NUM
                            }
                        })
                        this.eventState = res['GovernEventStatusAnalysises[]'].filter((f, i) => i == 1 || i == 2 || i == 3)
                        this.eventLightList = res['GovernEventStatusAnalysises[]'].filter((f, i) => i == 4 || i == 5 || i == 6)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getCenterRight() {
            let par = {
                "GovernEventFeedbackAnalysises[]": {
                    "GovernEventFeedbackAnalysis": {
                        "@column": "CATEGORY_NAME,CATEGORY_RATE"
                    },
                    "count": 0,
                    "query": 2
                }
            }
            requestPostBi('', par)
                .then((res) => {
                    if (res.code == 200) {
                        this.options.series[0].data = res['GovernEventFeedbackAnalysises[]'].map((item) => ({
                            name: item.GovernEventFeedbackAnalysis.CATEGORY_NAME,
                            value: item.GovernEventFeedbackAnalysis.CATEGORY_RATE,
                        }))
                        this.options.legend = [
                            {
                                data: this.options.series[0].data
                                    .map((item) => item.name)
                                    .splice(0, 3),
                                type: '',
                                itemGap: 15,
                                left: '-5',
                                top: '5%',
                                orient: 'vertical',
                                textStyle: {
                                    fontSize: 18,
                                    color: '#fff',
                                },
                            },
                        ]
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getDown() {
            let par = {
                "GovernCommunityRanks[]": {
                    "GovernCommunityRank": {
                        "@column": "COMMUNITY_NAME,RESPONSE_RATE,COMPLETION_RATE,SATISFACTION_RATE"
                    },
                    "count": 0,
                    "query": 2
                }
            }
            requestPostBi('', par)
                .then((res) => {
                    if (res.code == 200) {
                        let tempList = res['GovernCommunityRanks[]'].map((item, index) => ({
                            num: index + 1,
                            ...item
                        }))
                        this.tableData = this.groupList(tempList, 4)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
    },
}
</script>
  
  <style scoped  lang="scss">
.box {
    @include flex(column);
    padding: 10px 30px 20px 30px;
    .title {
        height: 40px;
        line-height: 40px;
    }
    .border-bottom {
        padding: 10px 0 48px 0;
        border-bottom: 2px solid;
        border-image: linear-gradient(
                to right,
                rgba(8, 0, 0, 0),
                #ef9c00,
                rgba(8, 0, 0, 0)
            )
            2;
        ::v-deep .el-date-editor {
            background-color: rgb(0, 0, 0, 0.2);
            border: #ef9c00 1px solid;
            float: right;
            .el-range-input {
                background-color: rgb(0, 0, 0, 0.2);
            }
        }

        .i-box {
            @include flex();
            flex-wrap: wrap;
            width: 100%;
            .item {
                @include flex(column);
                height: 45px;
                width: 46%;
                margin: 15px 30px 0 0;
                justify-content: space-between;
                section {
                    @include flex();
                    :nth-child(2) {
                        flex: 1;
                        margin-left: 26px;
                    }
                }
                .tiaoBgc {
                    width: 100%;
                    height: 8px;
                    display: inline-block;
                    background-color: #302710;
                }
                .tiao {
                    animation: fadeInLeft 1s ease;
                    height: 8px;
                    background: linear-gradient(
                        to right,
                        rgba(8, 0, 0, 0) 0%,
                        rgba(255, 0, 0, 0) 50%,
                        #ef9c00 100%
                    );
                }
            }
        }
    }
    .dispose-box {
        @include flex();
        height: 365px;
        justify-content: space-around;
        padding: 0;
        .left {
            @include flex(column);
            justify-content: space-around;
            border-right: 2px solid;
            border-image: linear-gradient(
                    to bottom,
                    rgba(8, 0, 0, 0),
                    #ef9c00,
                    rgba(8, 0, 0, 0)
                )
                2;
            flex: 1;
            .left-header {
                @include flex();
                > :nth-child(2) {
                    @include flex(column);
                    padding-left: 14px;
                    justify-content: space-between;
                    :nth-child(1) {
                        font-size: 36px;
                        color: #30fdff;
                        font-weight: 600;
                    }
                }
            }
            .left-body {
                @include flex();
                justify-content: space-around;
                section {
                    @include flex(column);
                    align-items: center;
                    justify-content: space-between;
                    height: 73px;
                    i {
                        font-size: 32px;
                        color: #30fdff;
                        font-weight: 600;
                    }
                }
            }
            .left-bottom {
                @include flex();
                justify-content: space-around;
                section {
                    @include flex(column);
                    align-items: center;
                    justify-content: space-between;
                    height: 73px;
                    div {
                        @include flex();
                        align-items: center;
                        img {
                            margin-right: 10px;
                        }
                    }
                    i {
                        font-size: 32px;
                        font-weight: 600;
                    }
                }
            }
        }
        .right {
            width: 380px;
            div {
                margin: 19px 0 0 19px;
            }
        }
    }
    .table {
        @include flex(column);
        margin-top: 20px;

        .lucency {
            background: rgba(32, 39, 56, 0.5);
        }
        .lucencys {
            background: rgba(32, 39, 56, 0.8);
        }

        .table_header {
            @include flex();
            justify-content: space-around;
            text-align: center;
            span {
                height: 50px;
                line-height: 50px;
            }
            :nth-child(1) {
                width: 50px;
            }
            :nth-child(2) {
                flex: 1;
            }
            :nth-child(2) {
                width: 200px;
            }
            :nth-child(3) {
                width: 150px;
            }
            :nth-child(4) {
                width: 150px;
            }
            :nth-child(5) {
                width: 150px;
            }
        }

        .t-list {
            @include flex(column);
            flex: 1;
            .swiper-container {
                height: 200px;
                width: 100%;
            }
            .item {
                @include flex();
                align-items: center;
                height: 50px;
                justify-content: space-around;
                background: rgba($color: #202738, $alpha: 0.5);
                cursor: pointer;
                span {
                    text-align: center;
                }
                > :nth-child(1) {
                    width: 50px;
                }
                > :nth-child(2) {
                    flex: 1;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                > :nth-child(2) {
                    width: 200px;
                }
                > :nth-child(3) {
                    width: 150px;
                }
                > :nth-child(4) {
                    width: 150px;
                }
                > :nth-child(5) {
                    width: 150px;
                }
            }
        }
    }
    .lucency {
        background: rgba(32, 39, 56, 0.8) !important;
    }
}
</style>
  