<template>
    <ek-panel title="区域管控分析">
        <div class="box">
            <div class="i-box">
                <div class="t-header">
                    <span class="num">序号</span>
                    <span class="name">区域名称</span>
                    <span class="company">诉求类型</span>
                    <span class="time">事件数量</span>
                </div>
                <div class="t-list">
                    <div class="item"
                         v-for="(item, index) in items"
                         :key="index">
                        <span>{{ index + 1 }}</span>
                        <span>{{ item.name }}</span>
                        <div>
                            <span> {{ item.level }}</span>
                            <section class="tiaoBgc">
                                <div class="tiao"
                                     :style="{ width: item.width }"></div>
                            </section>
                        </div>
                        <span>{{ item.total }}</span>
                    </div>
                </div>
            </div>
        </div>
    </ek-panel>
</template>

<script>
import { requestPostBi } from '@/utils/requestMiddleground'
export default {
    data() {
        return {
            items: [],
            totalSum: '',
        }
    },
    created() {
        this.getList()
    },
    methods: {
        getList() {
            let par = {
                "GovernRegionAnalysises[]": {
                    "GovernRegionAnalysis": {
                        "@column": "AREA_NAME,EVENT_TYPE,EVENT_NUM",
                        "EVENT_TYPE": "民生诉求"
                    },
                    "count": 0,
                    "query": 2
                }
            }
            requestPostBi('', par)
                .then((res) => {
                    if (res.code == 200) {
                        const sum = res['GovernRegionAnalysises[]'].reduce((a, b) => {
                            a = a + b.GovernRegionAnalysis.EVENT_NUM
                            return a
                        }, 0)
                        this.items = res['GovernRegionAnalysises[]'].map((item) => ({
                            name: item.GovernRegionAnalysis.AREA_NAME,
                            level: item.GovernRegionAnalysis.EVENT_TYPE,
                            total: item.GovernRegionAnalysis.EVENT_NUM,
                            width: (item.GovernRegionAnalysis.EVENT_NUM / sum) * 100 + '%',
                        }))
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
    },
}
</script>

<style scoped lang="scss">
.box {
    @include flex();
    height: 340px;
    padding: 10px 30px;
}

.i-box {
    @include flex(column);
    overflow-y: hidden;
    width: 100%;
    .t-header {
        @include flex();
        align-items: center;
        height: 60px;
        span {
            font-size: 20px;
            text-align: center;
        }
    }

    .t-header > span:nth-child(1),
    .t-list > .item > span:nth-child(1) {
        flex: 1;
    }
    .t-header > span:nth-child(2),
    .t-list > .item > span:nth-child(2) {
        flex: 2;
    }
    .t-header > span:nth-child(3),
    .t-list > .item > div:nth-child(3) {
        @include flex();
        text-align: left;
        flex: 4;
        align-items: center;
        span {
            width: 120px;
            margin-right: 24px;
        }
        .tiaoBgc {
            width: 100%;
            height: 8px;
            display: inline-block;
            background-color: #302710;
        }
        .tiao {
            animation: fadeInLeft 1s ease;
            height: 8px;
            background: linear-gradient(
                to right,
                rgba(8, 0, 0, 0) 0%,
                rgba(255, 0, 0, 0) 50%,
                #ef9c00 100%
            );
        }
    }
    .t-header > span:nth-child(4),
    .t-list > .item > span:nth-child(4) {
        flex: 1;
    }

    .t-list {
        @include flex(column);
        .item {
            width: 100%;
            @include flex();
            align-items: center;
            height: 45px;
            span {
                text-align: center;
            }
        }
    }

    .t-list > .item:nth-child(odd) {
    }
}
</style>
