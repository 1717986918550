<template>
  <div class="container">
    <l-1></l-1>
  </div>
</template>

<script>
import L1 from './components/l1.vue'
export default {
  components: { L1 },
  data() {
    return {}
  },
}
</script>

<style scoped lang="scss">
.container {
  @include flex(column);
  justify-content: space-between;
  align-content: space-between;
}
</style>
