<template>
    <ek-panel title="五大图层资源统计">
        <div class="box">
            <div class="chart">
                <ek-select :list="tuceng"
                           width="100px"
                           @click="handleTuceng" />
                <ek-chart :options="options"
                          :animation="true"
                          :animationData="options.xAxis.data" />
            </div>
            <div class="table">
                <p>场所列表</p>
                <div class="table_header lucencys">
                    <span>序号</span>
                    <span>场所名称</span>
                    <span>场所类型</span>
                    <span>规模</span>
                    <span>负责人</span>
                    <span>联系电话</span>
                </div>
                <div class="t-list">
                    <swiper ref="orderSwiper"
                            :options="swiperOptions">
                        <swiper-slide v-for="(items, index) in tableData"
                                      :key="index">
                            <div :class="[
                  'item',
                  index == 1 ? 'lucency' : index == 3 ? 'lucency' : '',
                ]"
                                 v-for="(item, index) in items"
                                 :key="index">
                                <span>{{ item.num }}</span>
                                <span>{{ item.GovernLayer.PLACE_NAME }}</span>
                                <span>{{ item.GovernLayer.PLACE_TYPE }}</span>
                                <span>{{ item.GovernLayer.PLACE_SCALE }}人</span>
                                <span>{{ item.GovernLayer.PLACE_HEAD }}</span>
                                <span>{{ item.GovernLayer.MOBILE }}</span>
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
        </div>
    </ek-panel>
</template>
    
    <script>
import { requestPostBi } from '@/utils/requestMiddleground'
export default {
    data() {
        let barTopColor = [
            '#a8602b',
            '#fbe947',
            '#3c8e43',
            '#4ea7a7',
            '#9a3b3a',
            '#094592',
            '#1d7441',
            '#56ccf2',
        ]
        let barTopColor1 = [
            '#ff903e',
            '#fbe947',
            '#58d764',
            '#76ffff',
            '#eb5757',
            '#0663da',
            '#27ae60',
            '#56ccf2',
        ]

        return {
            tableData: [],
            swiperOptions: {
                direction: 'vertical',
                autoplay: {
                    delay: 6000,
                    disableOnInteraction: false,
                },
                speed: 900,
                loop: false,
            },
            options: {
                grid: {
                    top: '5%',
                    left: '1%',
                    right: '1%',
                    bottom: 0,
                    containLabel: true,
                },
                tooltip: {
                    trigger: 'axis',
                    backgroundColor: 'transparent',
                    borderColor: 'transparent',
                    padding: 0,
                    axisPointer: {
                        type: 'none',
                    },
                    formatter: (params) => {
                        let el = ''
                        el = `<div class="tooltip-box column center">
                      <div class="text marginTop">
                        <span class="t">${params[2].name}：</span>
                        <span class="v">${params[2].value}个</span>
                      </div>
                    </div>`
                        // })
                        return el
                    },
                },
                xAxis: {
                    data: [],
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: true,
                        margin: 15,
                        textStyle: {
                            fontSize: 14,
                            color: '#ffffff',
                        },
                        interval: 0,//代表显示所有x轴标签显示
                    },
                    interval: 0,
                },
                yAxis: {
                    splitLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                },
                series: [
                    {
                        name: '柱顶部',
                        type: 'pictorialBar',
                        symbolSize: [80, 30],
                        symbolOffset: [0, -15],
                        borderColor: '#3c8e43',
                        borderWidth: '5',
                        z: 20,
                        itemStyle: {
                            normal: {
                                color: function (params) {
                                    return barTopColor1[params.dataIndex]
                                },
                                opacity: 1,
                                shadowBlur: 10,
                                shadowColor: 'rgba(0, 0, 0, 0.5)',
                                shadowOffsetX: 0,
                                shadowOffsetY: 0,
                                // 下面是设置阴影宽度的属性
                                shadowStyle: {
                                    width: 5,
                                },
                            },
                        },
                        label: {
                            show: true,
                            position: 'top',
                            fontSize: 16,
                            // formatter: function(params, index) {
                            //     return (params.value / sum * 80).toFixed(2) + '%';
                            // },
                        },
                        symbolPosition: 'end',
                        data: [],
                    },
                    {
                        name: '柱底部',
                        type: 'pictorialBar',
                        symbolSize: [80, 20],
                        symbolOffset: [0, 10],
                        z: 12,
                        itemStyle: {
                            normal: {
                                color: function (params) {
                                    return barTopColor[params.dataIndex]
                                },
                            },
                        },
                        data: [],
                    },
                    {
                        type: 'bar',
                        itemStyle: {
                            normal: {
                                color: function (params) {
                                    return barTopColor[params.dataIndex]
                                },
                                opacity: 1,
                            },
                        },
                        z: 16,
                        silent: true,
                        barWidth: 80,
                        barGap: '-100%', // Make series be overlap
                        data: [],
                    },
                    {
                        name: '最上层立体圆',
                        type: 'pictorialBar',
                        symbolSize: [80, 30],
                        symbolOffset: [0, -20],
                        z: 12,
                        itemStyle: {
                            color: function (params) {
                                return barTopColor[params.dataIndex]
                            },
                            opacity: 0.7,
                        },
                        data: [
                            {
                                value: 0,
                                symbolPosition: 'end',
                            },
                            {
                                value: 0,
                                symbolPosition: 'end',
                            },
                            {
                                value: 0,
                                symbolPosition: 'end',
                            },
                            {
                                value: 0,
                                symbolPosition: 'end',
                            },
                            {
                                value: 0,
                                symbolPosition: 'end',
                            },
                            {
                                value: 0,
                                symbolPosition: 'end',
                            },
                            {
                                value: 0,
                                symbolPosition: 'end',
                            },
                            {
                                value: 0,
                                symbolPosition: 'end',
                            },
                            {
                                value: 0,
                                symbolPosition: 'end',
                            },
                        ],
                    },
                    {
                        //上部立体柱
                        stack: '1',
                        type: 'bar',
                        itemStyle: {
                            // normal: {
                            //     color: '#00',
                            //     opacity: .7
                            // },
                            normal: {
                                color: function (params) {
                                    return barTopColor[params.dataIndex]
                                },
                                opacity: 0.2,
                            },
                        },
                        silent: true,
                        barWidth: 80,
                        barGap: '-100%', // Make series be overlap
                        data: [],
                    },
                ],
            },
            tuceng: [{ value: 0, label: '安全生产图层' }],
        }
    },
    mounted() {
        this.getBarList()
        this.getTableList()
    },
    methods: {
        getBarList() {
            let par = {
                "GovernLayerAnalysises[]": {
                    "GovernLayerAnalysis": {
                        "@column": "CATEGORY_NAME,CATEGORY_NUM",
                        "LAYER_NAME": "安全生产"
                    },
                    "count": 0,
                    "query": 2
                }
            }
            requestPostBi('', par)
                .then((res) => {
                    if (res.code == 200) {
                        let seriesData1 = res['GovernLayerAnalysises[]'].map(
                            (item) => item.GovernLayerAnalysis.CATEGORY_NUM
                        ).splice(0, 8)
                        let xAxisData = res['GovernLayerAnalysises[]'].map(
                            (item) => item.GovernLayerAnalysis.CATEGORY_NAME
                        ).splice(0, 8)
                        let sum = res['GovernLayerAnalysises[]'].reduce((a, b) => {
                            if (b.GovernLayerAnalysis.CATEGORY_NUM) {
                                a = a + b.GovernLayerAnalysis.CATEGORY_NUM
                            }
                            return a
                        }, 0)
                        this.options.series[0].data = seriesData1
                        this.options.series[1].data = seriesData1
                        this.options.series[2].data = seriesData1
                        this.options.xAxis.data = xAxisData
                        let arr = []
                        for (let i in this.options.series[3].data) {
                            this.options.series[3].data[i].value = sum
                            arr.push(this.options.series[3].data[i].value)
                            this.options.series[4].data = arr
                        }
                    }

                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getTableList() {
            let par = {
                "GovernLayers[]": {
                    "GovernLayer": {
                        "@column": "ID,PLACE_NAME,PLACE_TYPE,PLACE_SCALE,PLACE_HEAD,MOBILE"
                    },
                    "count": 0,
                    "query": 2
                }
            }

            requestPostBi('', par)
                .then((res) => {
                    // this.tableData = res['PlaceResource[]']
                    let tempList = res['GovernLayers[]'].map((item, index) => ({
                        num: index + 1,
                        ...item,
                    }))
                    this.tableData = this.groupList(tempList, 4)
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        handleTuceng() { },
    },
}
</script>
    
    <style scoped lang="scss">
.box {
    @include flex();
    padding: 10px 30px;
    justify-content: space-between;
    pointer-events: all;
    .chart {
        width: 834px;
        height: 280px;
        ::v-deep .el-select {
            width: 260px !important;
        }
    }
    .table {
        @include flex(column);
        margin-top: 20px;
        width: 50%;
        .lucency {
            background: rgba(32, 39, 56, 0.5);
        }
        .lucencys {
            background: rgba(32, 39, 56, 0.8);
        }

        .table_header {
            @include flex();
            justify-content: space-around;
            text-align: center;
            span {
                height: 50px;
                line-height: 50px;
            }
            :nth-child(1) {
                width: 50px;
            }
            :nth-child(2) {
                flex: 1;
            }
            :nth-child(3) {
                width: 200px;
            }
            :nth-child(4) {
                width: 100px;
            }
            :nth-child(5) {
                width: 150px;
            }
            :nth-child(6) {
                width: 150px;
            }
        }

        .t-list {
            @include flex(column);
            flex: 1;
            .swiper-container {
                height: 200px;
                width: 100%;
            }
            .item {
                @include flex();
                align-items: center;
                height: 50px;
                justify-content: space-around;
                background: rgba($color: #202738, $alpha: 0.5);
                cursor: pointer;
                span {
                    text-align: center;
                }
                :nth-child(1) {
                    width: 50px;
                }
                :nth-child(2) {
                    flex: 1;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                :nth-child(3) {
                    width: 200px;
                }
                :nth-child(4) {
                    width: 100px;
                }
                :nth-child(5) {
                    width: 150px;
                }
                :nth-child(6) {
                    width: 150px;
                }
            }
        }
    }
    .lucency {
        background: rgba(32, 39, 56, 0.8) !important;
    }
}
</style>
    