<template>
  <div class="container jqzl">
    <div class="fade-left">
      <left />
    </div>
    <div class="fade-center">
      <center />
    </div>
    <div class="fade-right">
      <right />
    </div>
    <div class="popup-center-wrapper"></div>
  </div>
</template>

<script>
import Left from './Left'
import Right from './Right'
import Center from './Center'
export default {
  components: {
    Left,
    Right,
    Center,
  },
}
</script>

<style scoped lang="scss">
.jqzl {
  @include flex(row);
  justify-content: center;
  position: relative;
  .fade-left,
  .fade-right {
    position: absolute;
    top: 0;
    bottom: 0;
    width: $barWidth;
    pointer-events: all;
    padding: 20px 30px 30px 30px;
  }

  .fade-left {
    left: 0;
    animation: fadeInLeft 1s ease;
    padding: 5px 0 18px 50px;
  }
  .fade-center {
    position: absolute;
    top: 100px;
    bottom: 0;
    width: $centerWidth;
    padding: 0 50px 18px;
  }
  .fade-right {
    right: 0;
    animation: fadeInRight 1s ease;
    padding: 5px 50px 18px 0px;
  }
  .map-wrapper {
    flex: 1;
    padding: 20px 0 30px 0;
    position: relative;
  }
  .popup-center-wrapper {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: all;
    transition: opacity 0.5s;
    z-index: 99;
  }
}
</style>
