<template>
    <ek-panel title="舆情监控词云">
        <div class="box">
            <wordcloud :data="defaultWords"
                       nameKey="name"
                       valueKey="value"
                       :color="myColors"
                       :showTooltip="false"
                       :rotate="{
          from: 0,
          to: 0,
          numOfOrientation: 0,
        }"
                       :spiral="'rectangular'">
            </wordcloud>
        </div>
    </ek-panel>
</template>

<script>
import wordcloud from 'vue-wordcloud'
import { requestPostBi } from '@/utils/requestMiddleground'
export default {
    data() {
        return {
            items: [
                { label: '商业办公', num: 70.53 },
                { label: '住宅', num: 70.96 },
                { label: '工业', num: 6.1 },
                { label: '其他', num: 5.32 },
            ],
            options: {
                title: {
                    text: `建筑面积\n比例`,
                    top: '44%',
                    left: '49%',
                    textAlign: 'center',
                    textStyle: {
                        color: '#fff',
                        fontSize: 18,
                        fontWeight: '400',
                    },
                },
                tooltip: {
                    trigger: 'item',
                },
                series: [
                    {
                        name: '建筑面积比例',
                        type: 'pie',
                        radius: ['36%', '70%'],
                        label: {
                            color: '#fff',
                            formatter: `{b} {d}%`,
                            fontSize: 18,
                        },
                        data: [
                            { value: 1048, name: '商业' },
                            { value: 735, name: '住宅' },
                            { value: 580, name: '其他' },
                            { value: 484, name: '工业' },
                        ],
                    },
                ],
            },
            myColors: ['#f6d203', '#64a924', '#e9a603', '#d8b805'],
            defaultWords: [],
            value: [
                '50',
                '50',
                '40',
                '40',
                '40',
                '20',
                '20',
                '20',
                '20',
                '20',
                '20',
                '20',
                '10',
                '10',
                '10',
                '10',
            ],
            word_name: "交通,步行街道路,垃圾分类,指示牌,噪音,商业,环境休闲,文化建设,游客,人流量,节假日,个体工商户,党员商户,停车位"
        }
    },
    created() {
        // this.getFakeData()
        this.defaultWords = this.word_name.split(',').map((item, index) => ({ name: item, value: this.value[index] }))
    },
    methods: {
        // wordClickHandler(name, value, vm) {
        //   console.log('wordClickHandler', name, value, vm)
        // },
        getFakeData() {
            let par = {
                WordCloud: {
                    '@column': 'word_name',
                },
            }
            requestPostBi('', par)
                .then((res) => {
                    this.defaultWords = res.WordCloud.word_name
                        .split(',')
                        .map((item, index) => ({ name: item, value: this.value[index] }))
                })
                .catch((err) => {
                    console.log(err)
                })
        },
    },
    components: { wordcloud },
}
</script>

<style scoped lang="scss">
.box {
    height: 291px - 30px;
    padding: 15px 20px;
}
.wordCloud {
    height: 291px - 30px;
}
</style>
