<template>
    <ek-panel title="商户管控分析">
        <div class="box">
            <ek-chart :options="options"
                      :animationData="options.series[0].data" />
        </div>
    </ek-panel>
</template>

<script>
import { requestPostBi } from '@/utils/requestMiddleground'
export default {
    data() {
        return {
            chartData: [],
            options: {
                tooltip: {
                    trigger: 'item',
                    confine: true,
                    backgroundColor: 'transparent',
                    borderColor: 'transparent',
                    padding: 0,
                    formatter: params => {
                        let el = ''
                        el = `<div class="tooltip-box row">
                      <div class="text" style="font-size:18px">
                        <span class="t">${params.name}：</span>
                        <span class="v">${params.value}次</span>
                      </div>
                    </div>`
                        return el
                    }
                },
                legend: [],
                series: [
                    {
                        name: '',
                        type: 'pie',
                        radius: ['50%', '70%'],
                        center: ['75%', '50%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center',
                        },
                        itemStyle: {
                            borderWidth: 2,
                            borderColor: '#4A4D4D',
                        },
                        emphasis: {
                            label: {
                                formatter: `{b} \n {d}%`,
                                show: true,
                                fontSize: 18,
                                borderWidth: 0,
                                color: '#fff',
                            },
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [],
                    },
                ],
            },
        }
    },
    created() {
        this.getPeiList()
    },
    methods: {
        getPeiList() {
            const par = {
                "GovernMerchantAnalysises[]": {
                    "GovernMerchantAnalysis": {
                        "@column": "ID,CATEGORY_NAME,CATEGORY_NUM"
                    },
                    "count": 0,
                    "query": 2
                }
            }
            requestPostBi('', par)
                .then((res) => {
                    let arr = res['GovernMerchantAnalysises[]'].map((item) => ({
                        name: item.GovernMerchantAnalysis.CATEGORY_NAME,
                        value: item.GovernMerchantAnalysis.CATEGORY_NUM,
                    }))
                    this.options.series[0].data = arr
                    this.options.legend = [
                        {
                            data: arr.map((item) => item.name).splice(0, 5),
                            left: '2%',
                            top: '5%',
                            itemGap: 5,
                            formatter: (name) => {
                                let val = ''
                                for (let i = 0, l = arr.length; i < l; i++) {
                                    if (arr[i].name == name) {
                                        val = arr[i].value
                                    }
                                }
                                return `{name|${name}}` + ` \n ` + `{val|${val}次} `
                            },
                            textStyle: {
                                color: '#fff',
                                rich: {
                                    name: {
                                        fontSize: 17,
                                        padding: [30, 0, 10, 0],
                                    },
                                    val: {
                                        fontSize: 17,
                                        // padding: [10, 0],
                                    },
                                },
                            },
                        },
                        {
                            data: arr.map((item) => item.name).splice(5, 5),
                            top: '35%',
                            left: '2%',
                            itemGap: 15,
                            formatter: (name) => {
                                let val = ''
                                for (let i = 0, l = arr.length; i < l; i++) {
                                    if (arr[i].name == name) {
                                        val = arr[i].value
                                    }
                                }
                                return `{name|${name}}` + ` \n ` + `{val|${val}次} `
                            },
                            textStyle: {
                                color: '#fff',
                                rich: {
                                    name: {
                                        fontSize: 17,
                                        padding: [30, 0, 10, 0],
                                    },
                                    val: {
                                        fontSize: 17,
                                        // padding: [10, 0],
                                    },
                                },
                            },
                        },
                        {
                            data: arr.map((item) => item.name).splice(10, 15),
                            top: '60%',
                            left: '2%',
                            itemGap: 5,
                            formatter: (name) => {
                                let val = ''
                                for (let i = 0, l = arr.length; i < l; i++) {
                                    if (arr[i].name == name) {
                                        val = arr[i].value
                                    }
                                }
                                return `{name|${name}}` + ` \n ` + `{val|${val}次} `
                            },
                            textStyle: {
                                color: '#fff',
                                rich: {
                                    name: {
                                        fontSize: 17,
                                        padding: [30, 0, 10, 0],
                                    },
                                    val: {
                                        fontSize: 17,
                                        // padding: [10, 0],
                                    },
                                },
                            },
                        },
                    ]
                })
                .catch((err) => {
                    console.log(err)
                })
        },
    },
    mounted() { },
}
</script>

<style scoped lang="scss">
.box {
    height: 240px;
}
</style>
